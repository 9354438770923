/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

// import Index from "views/Index.js";
import Landing from "views/examples/Landing.js";
import LearnMore from "views/examples/LearnMore.js";
import Gallery from "views/examples/Gallery.js";
import Contact from "views/examples/Contact.js";
import Testimonials from "views/examples/Testimonials.js";
// import toastr from "toastr";
import "toastr/build/toastr.css";

// toastr.options.timeOut = 2000;
// toastr.options.extendedTimeOut = 1000;

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* <Route path="/" exact render={props => <Index {...props} />} /> */}
      <Route
        path="/"
        exact
        render={props => <Landing {...props} />}
      />
        <Route
        path="/learnmore-page"
        exact
        render={props => <LearnMore {...props} />}
      />
      <Route
        path="/gallery-page"
        exact
        render={props => <Gallery {...props} />}
      />
      <Route path="/contact-page" exact render={props => <Contact {...props} />} />
      <Route path="/testimonials-page" exact render={props => <Testimonials {...props} />} />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
