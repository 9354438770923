/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Card, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class LearnMore extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            {/* Circles background */}
            <div className="shape shape-style-1 bg-gradient-default">
            </div>
            {/* SVG separator */}
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="11">
                  <Card className="bg-secondary shadow border-0" >
                    <div className="mt-0 py-5 border-top text-center bg-gradient-success">
                      <Row className="justify-content-center">
                        <Col lg="9">
                          <h2 className=" text-white">What is Microblading?</h2>
                          <h5 className="lead text-white" style={{margin: "40px"}} >
                            Microblading is a semi-permanent non-invasive
                            eyebrow procedure broken up into two short
                            appointments that is carried out by a qualified
                            technician using a specialist hand-tool which holds
                            disposable microblades.
                          </h5>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-0 py-5 border-top text-center bg-gradient-warning">
                      <Row className="justify-content-center">
                        <Col lg="9">
                          <h2 className=" text-white">Why Microblade?</h2>
                          <h5 className="lead text-white" style={{margin: "40px"}}>
                            This small procedure helps transform your existing
                            eyebrows and gives you the appearance that you have
                            make up on 24/7. Due to the process of microblading
                            it also allows for perfect shaping of your eyebrow
                            all year long. Many women love the fact that they
                            can customize their brow to their preference! Most
                            people who get their brows microbladed are very
                            happy with their results. Very few women get
                            microblading done and then regret their decision.
                          </h5>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-0 py-5 border-top text-center bg-gradient-primary">
                      <Row className="justify-content-center">
                        <Col lg="9">
                          <h2 className=" text-white">
                            What are the two procedures?
                          </h2>
                          <h5 className="lead text-white" style={{margin: "40px"}}>
                            The first is microblading and the second is
                            micro-shading, or just microblading shading, which
                            is known as the touch-up. Microblading makes your
                            brows look "done up" while shading makes it look
                            like you have make-up on 24/7. The second procedure
                            is super important because it is the procedure that
                            "locks in" your results and makes them last for a
                            long time.
                          </h5>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-0 py-5 border-top text-center bg-gradient-danger" >
                      <Row className="justify-content-center" >
                        <Col lg="9" >
                          <h2 className=" text-white">
                            How long does it last?
                          </h2>
                          <h5 className="lead text-white" style={{margin: "40px"}}>
                            Microblading is considered a semi-permanent
                            procedure which means that it lasts a long time but
                            it is not permanent. Microblading tends to last
                            between 9-36 months and after this period of time
                            may need a color boost or refresh. Depending on the
                            texture of your skin, how old you are and how much
                            oil your face produces these results may be longer
                            or shorter. Confusion can come from comparing it to
                            permanent make-up or tattoo's - they are not the
                            same. Microblading will place pigment into your skin
                            but because it doesn't go as deep as tattoo's it
                            will not be there forever. It's more like a "mini
                            tattoo" because pigment is placed in your skin but
                            it will fade 100% over time. If you decide you don't
                            like your brows you can also take certain steps to
                            increase the fading process (so don't let that scare
                            you).
                          </h5>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col>
                        <Button
                          color="success"
                          to="/gallery-page"
                          tag={Link}
                          style={{width: "140px", marginTop: "8px"}}
                        >
                          Gallery
                        </Button>
                        </Col>
                        <Col>
                        <Button
                          color="warning"
                          to="/contact-page"
                          tag={Link}
                          style={{width: "140px", marginTop: "8px"}}
                        >
                          Contact
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          color="primary"
                          to="/testimonials-page"
                          tag={Link}
                          style={{width: "140px", marginTop: "8px"}}
                        >
                          Testimonials
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default LearnMore;
