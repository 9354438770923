/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

// nodejs library that concatenates classes

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

// index page sections
// import Download from "../IndexSections/Download.js";

class Landing extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 bg-gradient-default">
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="10">
                      <h1 className="display-2 text-light">
                        A One Stop Shop for Beautiful Brows{" "}
                        <span className="text-white">
                          Discover the World of Microblading
                        </span>
                      </h1>
                      <p className="lead text-white">
                        Learn why so many have chosen the non-invasive
                        procedure, what all the hype is about, and decide if
                        this is right for you!
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-warning"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
              {/* 1st Hero Variation */}
              <Container>
                <Row className="justify-content-center">
                  <Col lg="12">
                    <Row className="row-grid">
                      <Col lg="4">
                        <Card className="card-lift--hover shadow border-0 bg-secondary">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <i className="ni ni-ruler-pencil" />
                            </div>
                            <h6 className="text-primary text-uppercase">
                              What is Microblading?
                            </h6>
                            <p className="description mt-3">
                              Microblading is a non-invasive procedure that
                              applies semi-permanent eyebrows to your existing
                              brows.
                            </p>

                            <Button
                              className="mt-4"
                              color="primary"
                              // href="#pablo"
                              // onClick={e => e.preventDefault()}
                              to="/learnmore-page"
                              tag={Link}
                            >
                              Learn more
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="4">
                        <Card className="card-lift--hover shadow border-0 bg-secondary">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                              <i className="ni ni-palette" />
                            </div>
                            <h6 className="text-success text-uppercase">
                              View Real Clients!
                            </h6>
                            <p className="description mt-3">
                              See for yourself why clients loved their results
                              with pictures from before and after their
                              appointments!
                            </p>

                            <Button
                              className="mt-4"
                              color="success"
                              // href="#pablo"
                              // onClick={e => e.preventDefault()}
                              to="/gallery-page"
                              tag={Link}
                            >
                              View Gallery
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="4">
                        <Card className="card-lift--hover shadow border-0 bg-secondary">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                              <i className="ni ni-calendar-grid-58" />
                            </div>
                            <h6 className="text-warning text-uppercase">
                              Contact Us Now!
                            </h6>
                            <p className="description mt-3">
                              Set up a free consultation for more information
                              over the phone, text or email and/or book an
                              appointment.
                            </p>

                            <Button
                              className="mt-4"
                              color="warning"
                              // href="#pablo"
                              // onClick={e => e.preventDefault()}
                              to="/contact-page"
                              tag={Link}
                            >
                              See Details
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>

          <section className="section section-lg section-shaped pb-250">
            <div className="shape shape-style-1 bg-gradient-success">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-default"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/microblading.jfif")}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-3 font-weight-bold text-white">
                        A Lasting Beauty
                      </h4>
                      <p className="lead text-italic text-white">
                        Enhance your eyebrows and join the many others who have
                        already received the Hollywood-ready look.
                      </p>
                    </blockquote>
                  </Card>
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle mb-5">
                      <i className="ni ni-satisfied" />
                    </div>
                    <h2 className="text-white font-weight-bold">
                      The Benefits
                    </h2>
                    <p className="lead text-white font-weight-bold">
                      Trade out having to make time to add eyebrows to your
                      makeup routine and enjoy the added benefit of a guaranteed
                      flawless finish.
                    </p>
                    <p className="lead text-white font-weight-bold">
                      Carried out by a certified technician, wake up everyday
                      with your brows looking as if they were just done by a
                      personal makeup artist.
                    </p>
                    <p className="lead text-white font-weight-bold">
                      With two quick sessions, feel the lasting satisfaction so
                      many others have felt.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg section-shaped pb-150">
            <div className="shape shape-style-1 bg-gradient-warning">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-light"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-2 ml-lg-auto" md="6">
                  <div className="position-relative pl-md-5">
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/theme/lookgoodfeelgood.png")}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="justify-content-center">
                  <h1
                    className="text-black font-weight-bold "
                    style={{ fontSize: "8vw" }}
                  >
                    Look Good, Feel Good
                  </h1>
                </Col>
              </Row>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-2 ml-lg-auto" md="6">
                  <div className="position-relative pl-md-5">
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/theme/3lookgoodfeelgood.jfif")}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg section-shaped ">
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-success"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3 text-white">Meet The Artist</h2>
                  <p className="lead text-white">
                    Certified since 2015, she has used Microblading to
                    successfully execute appointments and deliver satisfaction
                    to several clients.
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="12">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center shadow shadow-lg--hover"
                      src={require("assets/img/theme/Janet.png")}
                      style={{ width: "80%" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1 text-white">Janet</span>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-instagram" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Landing;
