/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
// import { InlineWidget } from "react-calendly";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import SingleTestimonial from "./SingleTestimonial";
import { Formik, Field } from "formik";
import { AddTestimonialSchema } from "../../Schemas/testimonialSchema";
import toast from "toastr";

const getUrl = () => {
  return `/default/testimonials`;
};

const getTestimonials = async () => {
  const response = await fetch(getUrl());
  const results = await response.json();
  const testimonials = results.testimonials;

  console.log("fetch results: ", testimonials);
  return testimonials;
};

export default function Testimonials() {
  const [testimonials, setTestimonials] = useState([]);
  const [formData, setFormData] = useState([{ customerName: "", message: "" }]);

  useEffect(() => {
    getTestimonials().then(setTestimonials);
  }, []);

  function mapTestimonial(oneTestimonial) {
    return (
      <SingleTestimonial
        key={oneTestimonial.customerName}
        testimonial={oneTestimonial}
      />
    );
  }

  console.log("after render: ", testimonials);

  function onChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const inputName = target.name;

    setFormData((prevFormData) => {
      let formData = { ...prevFormData };
      formData[inputName] = value;
      let customerNameValue = formData.customerName;
      let messageValue = formData.message;
      formData = { customerName: customerNameValue, message: messageValue };
      return formData;
    });
    console.log("change event name: ", inputName);
    console.log("change event value: ", value);
  }

  console.log("current state formData: ", formData);

  // function onAddTestimonialSuccess() {
  //   toast.success("Successfully added testimonial!");
  //   setTestimonials((prevTestimonials) => {
  //     const testimonials = [...prevTestimonials];
  //     testimonials.unshift(formData);

  //     return testimonials;
  //   });
  // }

  function handleSubmit() {
    // const testimonial = {
    //   customerName: formData.customerName,
    //   message: formData.message,
    // };
    // fetch(getUrl(), {
    //   method: "POST",
    //   body: JSON.stringify(testimonial),
    // }).then(onAddTestimonialSuccess);
    if (formData.customerName.length && formData.message.length > 0){
    toast.success("Thank you for submitting your testimonial! We will review your note as soon as possible!", {timeOut: 20000});
    }
  }

  return (
    <>
      <DemoNavbar />
      <main>
        <section className="section section-shaped section-lg">
          <div className="shape shape-style-1 bg-gradient-success">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="pt-lg-7">
            <Row className="justify-content-center">
              <Col>
                <Card>
                  <CardHeader className="bg-warning pb-2">
                    <div className="text-center mb-3">
                      <h5 className="text-white ">Testimonials</h5>
                    </div>
                  </CardHeader>
                  <CardBody className=" bg-gradient-success">
                  <div
                    
                  >
                    <Card className="bg-gradient-warning">
                      <Formik
                        enableReinitialize={true}
                        validationSchema={AddTestimonialSchema}
                        initialValues={formData}
                        onSubmit={handleSubmit}
                      >
                        {(props) => {
                          const {
                            values,
                            touched,
                            errors,
                            handleSubmit,
                            isValid,
                            isSubmitting,
                          } = props;
                          return (
                            <Container>
                              <Row>
                                <Col>
                                  <Form
                                    onSubmit={handleSubmit}
                                    className={"col-md-6 pt-4"}
                                  >
                                    <div>
                                      <label>
                                        <h1>Share your experience today!</h1>
                                      </label>
                                    </div>
                                    <FormGroup>
                                      <Label className="text-white">Name</Label>
                                      <Field
                                        name="customerName"
                                        type="text"
                                        values={values.customerName}
                                        onChange={onChange}
                                        placeholder="Jennifer S."
                                        autoComplete="off"
                                        className={
                                          errors.customerName &&
                                          touched.customerName
                                            ? "form-control error"
                                            : "form-control"
                                        }
                                      />
                                      {errors.customerName &&
                                        touched.customerName && (
                                          <span className="input-feedback text-danger">
                                            {errors.customerName}
                                          </span>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                      <Label className="text-white">
                                        Write about your experience below
                                      </Label>
                                      <Field
                                        name="message"
                                        type="text"
                                        values={values.message}
                                        onChange={onChange}
                                        placeholder="My Microblading experience was..."
                                        autoComplete="off"
                                        className={
                                          errors.message && touched.message
                                            ? "form-control error"
                                            : "form-control"
                                        }
                                      />
                                      {errors.message && touched.message && (
                                        <span className="input-feedback text-danger">
                                          {errors.message}
                                        </span>
                                      )}
                                    </FormGroup>
                                    <Button
                                      type="submit"
                                      disabled={!isValid || isSubmitting}
                                      style={{ marginBottom: "20px", marginTop: "20px" }} 
                                      color="success"
                                    >
                                      Share Testimonial
                                    </Button>
                                  </Form>
                                </Col>
                              </Row>
                            </Container>
                          );
                        }}
                      </Formik>
                    </Card>
                    {testimonials.map(mapTestimonial)}
                  </div>
                  </CardBody>
                </Card>
                <div className="mt-5 py-5 border-top text-center">
                  <Row className="justify-content-center">
                  <Col>
                      <Button color="primary" to="/learnmore-page" tag={Link} style={{width: "140px", marginTop: "8px"}}>
                        Learn More
                      </Button>
                    </Col>
                    <Col>
                      <Button color="warning" to="/gallery-page" tag={Link} style={{width: "140px", marginTop: "8px"}}>
                        Gallery
                      </Button>
                    </Col>
                    <Col>
                      <Button color="danger" to="/contact-page" tag={Link} style={{width: "140px", marginTop: "8px"}}>
                        Contact
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter />
    </>
  );
}
