/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  // Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
} from "reactstrap";

class DemoNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false,
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };
  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand
                id="navbar_global"
                className="mr-xl text-primary"
                // to="/"
                // tag={Link}
              >
                Brows-R-Us{" "}
                {/* <div className="icon-shape bg-gradient-primary rounded-circle text-white">
                  <i className="ni ni-bullet-list-67" />
                </div> */}
              </NavbarBrand>
              <NavbarBrand to="/" tag={Link}>
                Home
              </NavbarBrand>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav to="/learnmore-page" tag={Link}>
                      <i className="ni ni-ruler-pencil d-lg-none mr-1" />
                      <span className="nav-link-inner--text font-weight-bold">
                        LEARN MORE
                      </span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media
                          className="d-flex align-items-center"
                          to="/learnmore-page"
                          tag={Link}
                        >
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <i className="ni ni-ruler-pencil" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              What is Microblading?
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Learn more about the process
                            </p>
                          </Media>
                        </Media>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav to="/gallery-page" tag={Link}>
                      <i className="ni ni-palette d-lg-none mr-1" />
                      <span className="nav-link-inner--text font-weight-bold">
                        GALLERY
                      </span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media
                          className="d-flex align-items-center"
                          to="/gallery-page"
                          tag={Link}
                        >
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-palette" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-success mb-md-1">
                              Before and After
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              View photos with results from real clients
                            </p>
                          </Media>
                        </Media>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav to="/contact-page" tag={Link}>
                      <i className="ni ni-calendar-grid-58 d-lg-none mr-1" />
                      <span className="nav-link-inner--text font-weight-bold">
                        CONTACT
                      </span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media
                          className="d-flex align-items-center"
                          to="/contact-page"
                          tag={Link}
                        >
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-calendar-grid-58" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-warning mb-md-1">
                              Get ahold of the Artist
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Speak directly with the specialist
                            </p>
                          </Media>
                        </Media>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav to="/testimonials-page" tag={Link}>
                      <i className="ni ni-bell-55 d-lg-none mr-1" />
                      <span className="nav-link-inner--text font-weight-bold">
                        TESTIMONIALS
                      </span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media
                          className="d-flex align-items-center"
                          to="/testimonials-page"
                          tag={Link}
                        >
                          <div className="icon icon-shape bg-gradient-danger rounded-circle text-white">
                            <i className="ni ni-bell-55" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-danger mb-md-1">
                              View client testimonials
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Leave your review today
                            </p>
                          </Media>
                        </Media>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;
