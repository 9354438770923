import React from 'react'
import {
    // Button,
    Card,
    Row,
    Col,
  } from "reactstrap";

export default function SingleTestimonial(props) {
    const testimonial = props.testimonial;
    console.log(testimonial)

  return (
    <Card className="m-5 bg-gradient-warning">
    <div className="card-body">
    <p className="card-text text-white">{testimonial.customerName}</p>
    <h5 className="card-title text-white" style={{marginTop: "40px"}}>{testimonial.message}</h5>
    <div className="mt-3 py-3 border-top text-right">
<Row className="justify-content: space-between">
  <Col>
    {/* <Button
      type="button"
      color="primary"
      style={{width: "100px"}}
    >
      Edit
    </Button> */}
    &nbsp;&nbsp;&nbsp;&nbsp;
    {/* <Button
      type="button"
      color="danger"
      style={{width: "100px"}}
    >
      Delete
    </Button> */}
    </Col>
</Row>
</div>
  </div>
  </Card>
  )
}
