/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import { InlineWidget } from "react-calendly";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import StripeCheckout from "react-stripe-checkout";

class Contact extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  onToken = (token) => {
    console.log(token);
  }

  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col>
                  <Card>
                    <CardHeader className="bg-gradient-success pb-2">
                      <div className="text-center mb-3">
                        <h5 className="text-white ">
                          Use our calendar below to schedule your session today!
                        </h5>
                      </div>
                    </CardHeader>
                    <div>
                      <InlineWidget
                        url="https://calendly.com/johndoetesting/microblade-session-clone"
                        className="display-flex"
                      />
                    </div>
                  </Card>
                  <Row
                    className="justify-content-center"
                    style={{ margin: "50px" }}
                  >
                    <Col lg="7">
                      <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-gradient-primary pb-2">
                          <div className="text-center mb-3">
                            <h5 className="text-white ">
                              For a free informational consultation or to book
                              your appointment by phone or email, reach out
                              today!
                            </h5>
                          </div>
                        </CardHeader>
                        <CardBody className="text-center px-lg-5 py-lg-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle ">
                            <i className="ni ni-headphones" />
                          </div>
                          <div className="text-center pt-2 mb-5">
                            <h6> (616) 555-5555</h6>
                          </div>
                          <div className="icon icon-shape icon-shape-primary rounded-circle ">
                            <i className="ni ni-email-83" />
                          </div>
                          <div className="text-center pt-2 mb-5">
                            <h6> example@example.com</h6>
                          </div>
                          <div></div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row
                    className="justify-content-center"
                    style={{ margin: "50px" }}
                  >
                    <Col lg="7">
                      <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-gradient-warning pb-2">
                          <div className="text-center mb-3">
                            <h5 className="text-white ">Microblade Package Deal</h5>
                          </div>
                        </CardHeader>
                        <CardBody className="text-center px-lg-5 py-lg-5">
                        <div className="text-center pt-2 mb-5">
                            <h6>Secure both your Microblade and Microshade sessions today!</h6>
                          </div>
                          <StripeCheckout
                            token={this.onToken}
                            name="Book Dual Package"
                            currency="usd"
                            amount="10000"
                            stripeKey="pk_test_51M2Ps7DYs6WQVpmgtB7MXBMu8HTV6CySS349tH3W1O06y52p3pVKU1OoJ21P69mhns5XdLArG3X3NBcBpRLgbsti00HPHuoOnK"
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col>
                        <Button
                          color="primary"
                          to="/learnmore-page"
                          tag={Link}
                          style={{ width: "140px", marginTop: "8px" }}
                        >
                          Learn More
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          color="success"
                          to="/gallery-page"
                          tag={Link}
                          style={{ width: "140px", marginTop: "8px" }}
                        >
                          Gallery
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          color="warning"
                          to="/testimonials-page"
                          tag={Link}
                          style={{ width: "140px", marginTop: "8px" }}
                        >
                          Testimonials
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Contact;
