/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes

// reactstrap components
import { Button, Card, CardImg, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

// index page sections

class Gallery extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          {/* shape Hero */}
          <section className="section section-lg section-shaped">
            <div className="shape shape-style-1 bg-gradient-warning">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-7">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Photo Gallery</h2>
                  <p className="lead text-white">
                    All photos below were taken from real clients before and
                    after each indicated section. View the results for yourself!
                  </p>
                </Col>
              </Row>
              <Row className="row-grid mt-5">
                <Col lg="4">
                  <h5 className="text-white mt-3">Before</h5>
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/4beforeafterbefore.jpg")}
                      top
                    />
                  </Card>
                </Col>
                <Col lg="4">
                  <h5 className="text-white mt-3">After Microblading</h5>
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/4beforeafterfirst.jpg")}
                      top
                    />
                  </Card>
                </Col>
                <Col lg="4">
                  <h5 className="text-white mt-3">After Microshading</h5>
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/4beforeaftersecond.jpg")}
                      top
                    />
                  </Card>
                </Col>
              </Row>
              <Row className="row-grid mt-5">
                <Col lg="4">
                  <h5 className="text-white mt-3">Before</h5>
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/3beforeafterbefore.jpg")}
                      top
                    />
                  </Card>
                </Col>
                <Col lg="4">
                  <h5 className="text-white mt-3">After Microblading</h5>
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/3beforeafterfirst.jpg")}
                      top
                    />
                  </Card>
                </Col>
                <Col lg="4">
                  <h5 className="text-white mt-3">After Microshading</h5>
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/3beforeaftersecond.jpg")}
                      top
                    />
                  </Card>
                </Col>
              </Row>
              <div className="mt-5 py-5 border-top text-center">
                <Row className="justify-content-center">
                  <Col>
                    <Button color="primary" to="/learnmore-page" tag={Link} style={{width: "140px", marginTop: "8px"}}>
                      Learn More
                    </Button>
                  </Col>
                  <Col>
                    <Button color="danger" to="/contact-page" tag={Link} style={{width: "140px", marginTop: "8px"}}>
                      Contact
                    </Button>
                  </Col>
                  <Col>
                    <Button color="success" to="/testimonials-page" tag={Link} style={{width: "140px", marginTop: "8px"}}>
                      Testimonials
                    </Button>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Gallery;
